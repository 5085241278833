import React from "react";

const Variable = (props) => {
	return (
		<span
			className={`${
				props.classNames ? props.classNames : ""
			} text-indigo-600 text-sm px-3 py-1.5 rounded-lg mx-1`}
			style={{
				background:
					"transparent linear-gradient(228deg, rgba(100,101,230, 0.15) 0%, rgba(24,25,175, 0.15) 100%) 0% 0% no-repeat padding-box",
			}}
		>
			{props.text}
		</span>
	);
};

export default Variable;
