import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { SparklesIcon } from "@heroicons/react/20/solid";

import { useFormData } from "../../shared/context/document-context";
import useFetch from "../../shared/hooks/useFetch";

import Container from "../../shared/components/UIElements/Container";
import Heading from "../../shared/components/UIElements/Text/Heading";

import GradientBorder from "../../shared/components/UIElements/GradientBorder";
import DocumentMeta from "../components/View/DocumentMeta";
import CopyButton from "../components/View/CopyButton";
import FeedbackButton from "../components/Feedback/FeedbackButton";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import DocumentContent from "../components/View/DocumentContent";
import Header from "../../shared/components/UIElements/Header";

const GeneratingDocument = () => {
	const { formData } = useFormData();
	const navigate = useNavigate();

	const documentId = useParams().documentId;
	const [document, setDocument] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	const fetchDocument = useFetch();

	useEffect(() => {
		const fetchAndSetDocument = async () => {
			if (documentId === "generating") {
				setDocument(formData);
				setIsLoading(false);

				if (formData.id) {
					navigate(`/document/${formData.id}`, { replace: true });
				}
			} else if (documentId) {
				const uri = `/api/documents/${documentId}`;

				try {
					const data = await fetchDocument(uri);
					setDocument(data.document);
					setIsLoading(false);
				} catch (error) {
					console.error(error);
					setIsLoading(false);
				}
			} else {
				setIsLoading(false);
			}
		};

		if (documentId) {
			fetchAndSetDocument();
		}
	}, [documentId, navigate, formData]);

	if (isLoading) {
		return <LoadingSpinner />;
	}

	return (
		<>
			{documentId === "generating" && (
				<>
					<Container>
						<div className="flex items-center pt-12 -mb-2">
							<SparklesIcon className="h-7 w-7 mt-2 mr-2 text-indigo-600" />
							<Heading>
								Nieuw document aan het genereren...
							</Heading>
						</div>
					</Container>
					<GradientBorder />
				</>
			)}
			{documentId !== "generating" && (
				<Header
					title={document.documentType.title}
					description={"Jouw document"}
					icon={<SparklesIcon />}
				/>
			)}
			<Container className={"bg-gray-50 py-10"}>
				<div className="flex flex-wrap sm:flex-nowrap sm:flex-row flex-col-reverse">
					<div className="basis-full sm:basis-1/4 w-full sm:mr-10">
						<div className="pb-8">
							<DocumentMeta
								info={document.info}
								dateCreated={document.dateCreated}
							/>
							<CopyButton
								copyText={document.content}
								classNames="w-full"
							/>
						</div>
						<div className="border-t-2 pt-4 pb-6 text-center">
							<Heading size="4">
								Wat vind je van deze brief?
							</Heading>
							<p className="text-blueGray-600 text-xs mb-5 mt-1">
								Verbeteren zonder feedback lukt niet. Laat
								vooral weten wat er nog niet helemaal lekker
								gaat.
							</p>
							<FeedbackButton
								documentId={document.id}
								documentType={document.documentType.title}
							/>
						</div>
					</div>
					<div className="basis-full sm:basis-3/4 border-3 border-gray-200 p-8 sm:p-16 leading-7 rounded-3xl rounded-tl-none bg-white">
						<div className="sm:hidden mb-8 -mt-4">
							<CopyButton copyText={document.content} />
						</div>
						{documentId === "generating" && (
							<DocumentContent
								content={document.content}
								generating={true}
								vervangAanhef={
									document.documentType.vervangAanhef || false
								}
							/>
						)}
						{documentId !== "generating" && (
							<DocumentContent
								content={document.content}
								generating={false}
								vervangAanhef={
									document.documentType.vervangAanhef || false
								}
							/>
						)}
					</div>
				</div>
			</Container>
		</>
	);
};

export default GeneratingDocument;
