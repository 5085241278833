import React, { useEffect, useState } from "react";

import useFetch from "../../hooks/useFetch";

import Select from "./Select";
import { BoltIcon } from "@heroicons/react/24/solid";

const SelectTreatment = (props) => {
	const [treatments, setTreatments] = useState([]);

	const fetchTreatments = useFetch();

	useEffect(() => {
		fetchTreatments("/api/treatments")
			.then((data) => {
				setTreatments(data.treatments);
			})
			.catch((error) => console.error(error));
	}, []);

	return (
		<>
			<Select
				label="Behandeling"
				name="treatment"
				type="text"
				placeholder="Selecteren..."
				id="treatment"
				required
			>
				<option value="" disabled>
					Selecteer behandeling...
				</option>
				<option value={`{"type": "other", "name": ""}`}>
					Andere behandeling...
				</option>
				{treatments.map((treatment) => (
					<option
						value={`{"id": "${treatment.id}", "name": "${treatment.name}"}`}
						key={treatment.id}
					>
						{treatment.name}
					</option>
				))}
			</Select>
		</>
	);
};

const OtherTreatment = ({ selectedTreatment, setTreatment }) => {
	const handleInputChange = (event) => {
		setTreatment(`{"type": "other", "name": "${event.target.value}"}`);
	};

	let selectedTreatmentObject;

	function isJSON(str) {
		try {
			return JSON.parse(str) && !!str;
		} catch (e) {
			return false;
		}
	}

	if (selectedTreatment && isJSON(selectedTreatment)) {
		selectedTreatmentObject = JSON.parse(selectedTreatment);
	} else {
		selectedTreatmentObject = selectedTreatment;
	}

	return (
		selectedTreatmentObject.type === "other" && (
			<>
				<div>
					<div className="relative mt-2 rounded-md shadow-sm">
						<input
							name="otherTreatment"
							type="text"
							placeholder="Behandeling XYZ..."
							className="block w-full rounded-md border-0 py-1.5 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							onChange={handleInputChange}
						/>
						<div className="absolute inset-y-0 right-0 flex items-center">
							<span
								className={`text-indigo-600 text-xs px-3 py-1 rounded-md mx-1.5 font-medium flex`}
								style={{
									background:
										"transparent linear-gradient(228deg, rgba(100,101,230, 0.15) 0%, rgba(24,25,175, 0.15) 100%) 0% 0% no-repeat padding-box",
								}}
							>
								<BoltIcon className="h-3 mt-0.5 mr-0.5" />
								Beta
							</span>
						</div>
					</div>
				</div>
			</>
		)
	);
};

const TreatmentSelection = (props) => {
	const handleOtherTreatment = (value) => {
		console.log(value);
		props.setFieldValue("treatment", value);
	};

	return (
		<>
			<SelectTreatment />
			<OtherTreatment
				selectedTreatment={props.values.treatment}
				setTreatment={handleOtherTreatment}
			/>
		</>
	);
};

export default TreatmentSelection;

// import React, { useEffect, useState } from "react";

// import useFetch from "../../hooks/useFetch";

// import Select from "./Select";
// import LoadingSpinner from "../UIElements/LoadingSpinner";

// const SelectTreatment = () => {
// 	const [treatments, setTreatments] = useState([]);
// 	const [isLoading, setIsLoading] = useState(false);

// 	const fetchTreatments = useFetch();

// 	useEffect(() => {
// 		fetchTreatments("/api/treatments")
// 			.then((data) => {
// 				setTreatments(data.treatments);
// 			})
// 			.catch((error) => console.error(error));
// 	}, []);

// 	if (isLoading) {
// 		return <LoadingSpinner />;
// 	}

// 	return (
// 		<>
// 			<Select
// 				label="Behandeling"
// 				name="treatment"
// 				type="text"
// 				placeholder="Selecteren..."
// 				required
// 			>
// 				<option value="" selected disabled>
// 					Selecteer behandeling...
// 				</option>
// 				{treatments.map((treatment) => (
// 					<>
// 						<option
// 							value={`{"id": "${treatment.id}", "name": "${treatment.name}"}`}
// 						>
// 							{treatment.name}
// 						</option>
// 					</>
// 				))}
// 			</Select>
// 		</>
// 	);
// };

// // export default SelectTreatment;
