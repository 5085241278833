import React from "react";

const IconWithText = (props) => {
	return (
		<div className="mt-2 flex items-center text-sm text-gray-500">
			<div
				className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
				aria-hidden="true"
			>
				{props.icon}
			</div>
			{props.text}
		</div>
	);
};

export default IconWithText;
