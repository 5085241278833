import { useAuth } from "@clerk/clerk-react";
import { useCallback } from "react";

export default function useFetch() {
	const { getToken } = useAuth();
	const baseURL = process.env.REACT_APP_BACKEND_URL;

	const authenticatedFetch = useCallback(
		async (endpoint, ...args) => {
			return fetch(`${baseURL}${endpoint}`, ...args, {
				headers: { Authorization: `Bearer ${await getToken()}` },
			}).then((res) => res.json());
		},
		[getToken]
	);
	return authenticatedFetch;
}
