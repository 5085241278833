import React, { Suspense, useEffect } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";

import { SignedIn, SignedOut, useUser } from "@clerk/clerk-react";
import { useIntercom } from "react-use-intercom";

import { FormDataProvider } from "./shared/context/document-context";

import MainNavigation from "./shared/components/Navigation/MainNavigation";
import LoadingSpinner from "./shared/components/UIElements/LoadingSpinner";

import GenerateDocument from "./documents/pages/GenerateDocument";
import UserDocuments from "./documents/pages/UserDocuments";
import Profile from "./users/pages/Profile";
import SingleDocument from "./documents/pages/SingleDocument";
import AllDocuments from "./documents/pages/AllDocuments";
import DocumentTypes from "./documents/pages/DocumentTypes";
import SignUp from "./users/pages/SignUp";
import Login from "./users/pages/Login";

function App() {
	const routesSignedIn = [
		<Route path="/document/:documentId" element={<SingleDocument />} />,
		<Route path="/documenten" element={<AllDocuments />} />,
		<Route path="/mijn-documenten" element={<UserDocuments />} />,
		<Route path="/profile/" element={<Profile />} />,
		<Route path="/new/:documentType" element={<GenerateDocument />} />,
		<Route path="/" element={<DocumentTypes />} />,
		<Route path="*" element={<Navigate to="/" />} />,
	];

	const routesSignedout = [
		<Route path="/aanmelden" element={<SignUp />} />,
		<Route path="/inloggen" element={<Login />} />,
		<Route path="*" element={<Navigate to="/aanmelden" />} />,
	];

	const { boot } = useIntercom();
	const { user } = useUser();

	useEffect(() => {
		console.log(user);
		if (user) {
			const intercomUser = {
				user_id: user.id,
				email: user.primaryEmailAddress.emailAddress,
				name: `${user.firstName} ${user.lastName}`,
			};

			boot(intercomUser);
		}
	}, [boot, user]);

	return (
		<Router>
			<SignedIn>
				<FormDataProvider>
					<MainNavigation />
					<main>
						<Suspense fallback={<LoadingSpinner />}>
							<Routes>{routesSignedIn}</Routes>
						</Suspense>
					</main>
				</FormDataProvider>
			</SignedIn>
			<SignedOut>
				<Routes>{routesSignedout}</Routes>
			</SignedOut>
		</Router>
	);
}

export default App;
