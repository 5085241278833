import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useFetch from "../../shared/hooks/useFetch";
import { useAuth } from "@clerk/clerk-react";

import DocumentList from "../components/DocumentList";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import Header from "../../shared/components/UIElements/Header";
import Container from "../../shared/components/UIElements/Container";

const UserDocuments = () => {
	const { userId } = useAuth();

	const [documents, setDocuments] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	console.log(userId);
	const fetchDocs = useFetch();

	const fetchDocuments = async () => {
		const uri = `/api/documents/user/${userId}`;

		setIsLoading(true);

		fetchDocs(uri)
			.then((data) => {
				console.log(data);
				setDocuments(data.documents);
				setIsLoading(false);
			})
			.catch((error) => console.error(error));
	};

	useEffect(() => {
		fetchDocuments();
	}, [userId]);

	return (
		<>
			<Header
				title="Je documenten"
				subtitle="Elk document dat je gemaakt hebt met DentGPT"
				className="border-b-2 border-gray-100"
				style={{ backgroundColor: "#fff" }}
			/>
			<div className=" bg-slate-50 pb-24">
				<Container>
					{isLoading && (
						<div className="flex py-16 justify-center">
							<LoadingSpinner />
						</div>
					)}
					<DocumentList documents={documents} />
				</Container>
			</div>
		</>
	);
};

export default UserDocuments;
