import React, { useEffect, useState } from "react";
import useFetch from "../../shared/hooks/useFetch";

import Header from "../../shared/components/UIElements/Header";
import Container from "../../shared/components/UIElements/Container";
import TypeItem from "../components/TypeItem";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import RequestNewType from "../components/RequestNewType";

const DocumentTypes = () => {
	const [documentTypes, setDocumentTypes] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const fetchTypes = useFetch();

	const fetchDocumentTypes = async () => {
		const uri = `/api/documents/documentTypes`;

		setIsLoading(true);

		fetchTypes(uri)
			.then((data) => {
				let documentTypesByCategory = {};

				data.documentTypes.forEach((docType) => {
					const category = docType.categorie;

					if (!documentTypesByCategory[category]) {
						documentTypesByCategory[category] = [];
					}

					if (process.env.REACT_APP_STAGING !== "true") {
						if (docType.live === true) {
							documentTypesByCategory[category].push(docType);
						}
					} else {
						documentTypesByCategory[category].push(docType);
					}
				});

				setDocumentTypes(documentTypesByCategory);
				setIsLoading(false);
			})
			.catch((error) => console.error(error));
	};

	const translateCategory = (category) => {
		const categoryTranslations = {
			receptie: "Voor de receptie",
			praktijkmanager: "Voor de praktijkmanager",
			behandelaren: "Voor het behandelteam",
		};

		return categoryTranslations[category] || category;
	};

	useEffect(() => {
		fetchDocumentTypes();
	}, []);

	if (isLoading) {
		return <LoadingSpinner />;
	}

	return (
		<>
			<Header
				title="Verzameling brieven en documenten"
				subtitle="Maak je eerste document en ga aan de slag "
			/>
			<Container className="bg-blueGray-50 pt-2 pb-16">
				{Object.keys(documentTypes).map((category) => (
					<div key={category}>
						<h2 className="text-xl font-bold mt-14 mb-4">
							{translateCategory(category)}
						</h2>
						<div className="grid sm:grid-cols-3 gap-4 grid-cols-1">
							{documentTypes[category].map((documentType) => (
								<TypeItem
									key={documentType.id}
									id={documentType.id}
									title={documentType.title}
									description={documentType.description}
									slug={documentType.slug}
									live={documentType.live}
								/>
							))}
							<RequestNewType />
						</div>
					</div>
				))}
			</Container>
		</>
	);
};

export default DocumentTypes;
