import React from "react";
import Heading from "./Text/Heading";
import Container from "./Container";
import Variable from "./Text/Variable";

const Header = (props) => {
	return (
		<Container className="border-b-indigo-600 border-b-6">
			<div className="flex pt-12 sm:px-0">
				{props.label && (
					<div className="-mt-8 mb-10">
						<div className="absolute">
							<Variable text={props.label} />
						</div>
					</div>
				)}
				{props.icon && (
					<div className="hidden sm:block w-14 sm:w-7 mt-auto mr-3 text-indigo-600">
						{props.icon}
					</div>
				)}
				<div>
					{props.description && (
						<p className="text-lg font-medium -mb-1 text-blueGray-400">
							{props.description}
						</p>
					)}
					<Heading>{props.title}</Heading>
					{props.subtitle && (
						<p className="text-lg font-medium mt-2 text-blueGray-400">
							{props.subtitle}
						</p>
					)}
				</div>
			</div>
		</Container>
	);
};

export default Header;
