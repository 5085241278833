import React from "react";
import App from "./App";

import { IntercomProvider } from "react-use-intercom";

const IntercomAppWrapper = () => {
	const INTERCOM_APP_ID = "uxltrzl0";

	return (
		<IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
			<App />
		</IntercomProvider>
	);
};

export default IntercomAppWrapper;
