import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import MainLogo from "./MainLogo";
import NavItem from "./NavItem";
import ProfileDropdown from "./ProfileDropdown";
import Variable from "../UIElements/Text/Variable";

const navItems = [
	{
		key: "1",
		name: "Nieuw",
		link: "/",
		exact: true,
	},

	{
		key: "3",
		name: "Geschiedenis",
		link: "/mijn-documenten",
		exact: true,
	},
	// {
	// 	key: "4",
	// 	name: "Gebruikers",
	// 	link: "/gebruikers",
	// 	exact: true,
	// },
];
if (process.env.REACT_APP_STAGING === "true") {
	navItems.push({
		key: "2",
		name: "Alle brieven",
		link: "/documenten",
		exact: true,
	});
}

const MainNavigation = (props) => {
	return (
		<Disclosure as="nav" className="bg-white shadow z-10 relative">
			{({ open }) => (
				<>
					<div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
						<div className="relative flex justify-between h-16">
							<div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
								{/* Mobile menu button */}
								<Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
									<span className="sr-only">
										Open main menu
									</span>
									{open ? (
										<XMarkIcon
											className="block h-6 w-6"
											aria-hidden="true"
										/>
									) : (
										<Bars3Icon
											className="block h-6 w-6"
											aria-hidden="true"
										/>
									)}
								</Disclosure.Button>
							</div>
							<div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
								<div className="flex-shrink-0 flex items-center">
									<Link to="/" className="flex">
										<MainLogo />
										<Variable
											text="Beta"
											classNames="ml-2 mr-6"
										/>
									</Link>
								</div>
								<div className="hidden sm:ml-6 sm:flex sm:space-x-8">
									{/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
									{navItems.map((item) => (
										<NavItem
											key={item.key}
											name={item.name}
											link={item.link}
											exact={item.exact}
										/>
									))}
								</div>
							</div>

							<ProfileDropdown />
						</div>
					</div>

					<Disclosure.Panel className="sm:hidden">
						<div className="pt-2 pb-4 space-y-1">
							{/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
							{navItems.map((item) => (
								<Disclosure.Button
									as="a"
									href={item.link}
									className="focus:bg-indigo-50 focus:border-indigo-500 focus:text-indigo-700 border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
								>
									{item.name}
								</Disclosure.Button>
							))}
						</div>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	);
};

export default MainNavigation;
