import React, { useState } from "react";
import Input from "./Input";
import Textarea from "./Textarea";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";

const OtherField = () => {
	const [isOpen, setIsOpen] = useState(false);

	const handleClick = () => {
		setIsOpen(!isOpen);
	};

	return (
		<>
			<p
				onClick={handleClick}
				className="text-xs font-semibold text-gray-500 mt-5 flex align-middle uppercase tracking-wide cursor-pointer"
			>
				Geavanceerd
				{!isOpen && <ChevronDownIcon className="w-4" />}
				{isOpen && <ChevronUpIcon className="w-4" />}
			</p>
			{isOpen && (
				<Textarea
					label="Extra informatie"
					name="extraInformatie"
					placeholder="Bijvoorbeeld: de patient is bang voor de tandarts."
					type="text"
					required={false}
				/>
			)}
		</>
	);
};

export default OtherField;
