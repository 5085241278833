import React from "react";

import {
	ClockIcon,
	UserIcon,
	LifebuoyIcon,
	BriefcaseIcon,
} from "@heroicons/react/20/solid";

import FormattedDate from "../../../shared/components/util/FormattedDate";
import Heading from "../../../shared/components/UIElements/Text/Heading";
import IconWithText from "../../../shared/components/UIElements/IconWithText";

const DocumentMeta = (props) => {
	const gender = props.info.patientGender === "male" ? "Man" : "Vrouw";
	const patientText = `${gender} van ${props.info.patientAge} jaar`;

	return (
		<div className="bg-blueGray-100 p-8 pb-10 rounded-2xl">
			<Heading size="4">Patiëntinformatie</Heading>
			{props.info.patientGender && props.info.patientAge && (
				<IconWithText icon={<UserIcon />} text={patientText} />
			)}
			{props.info.patientOccupation && (
				<IconWithText
					icon={<BriefcaseIcon />}
					text={props.info.patientOccupation}
				/>
			)}
			{props.info.treatment && (
				<IconWithText
					icon={<LifebuoyIcon />}
					text={props.info.treatment.name}
				/>
			)}
			<IconWithText
				icon={<ClockIcon />}
				text={<FormattedDate date={props.dateCreated || new Date()} />}
			/>
		</div>
	);
};

export default DocumentMeta;
