import React from "react";

import { UserProfile } from "@clerk/clerk-react";

import Header from "../../shared/components/UIElements/Header";
import Container from "../../shared/components/UIElements/Container";

const Profile = () => {
	return (
		<>
			<Header
				title="Inlogvoorkeuren"
				subtitle="Pas je e-mailadres of wachtwoord aan"
			/>
			<Container>
				<div className="mt-profile">
					<UserProfile
						appearance={{
							elements: {
								card: {
									boxShadow: "none",
									marginLeft: "-25px",
								},
								header: "hidden",
								profileSection__activeDevices: "hidden",
								profileSectionPrimaryButton__emailAddresses:
									"hidden",
								navbar: "hidden",
								formFieldInput:
									"rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 px-3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
								formButtonPrimary:
									"bg-indigo-600 hover:bg-indigo-700 hover:scale-105 transition ease-in-out duration-300 rounded-lg normal-case text-base shadow-sm font-semibold tracking-normal py-3 text-white",
							},
						}}
					/>
				</div>
			</Container>
		</>
	);
};

export default Profile;
