import React, { useEffect, useState } from "react";

import { useUser } from "@clerk/clerk-react";

import Variable from "../../../shared/components/UIElements/Text/Variable";

const DocumentContent = (props) => {
	const [content, setContent] = useState("");
	const { user } = useUser();

	useEffect(() => {
		if (!props.generating && props.vervangAanhef === true) {
			const newGreeting = [
				"Beste ",
				<Variable text="Voornaam" />,
				", \n",
			];
			const newEnding = [
				"Hartelijke groet,\n\n",
				user.fullName,
				"\n",
				<b>{user.unsafeMetadata.praktijkNaam}</b>,
			];

			let lines = props.content.split("\n");

			lines[0] = newGreeting;
			lines.pop();
			lines.pop();

			lines[lines.length - 1] = newEnding;
			setContent(lines);
		}
	}, [
		props.content,
		props.generating,
		props.vervangAanhef,
		user.fullName,
		user.unsafeMetadata.praktijkNaam,
	]);

	return (
		<div id="documentContent">
			{(props.generating || !props.vervangAanhef) && (
				<p id="nietVervangAanhef" style={{ whiteSpace: "pre-wrap" }}>
					{props.content}
				</p>
			)}
			{!props.generating &&
				props.vervangAanhef &&
				content &&
				content.map((line, index) => {
					return (
						<p
							className="mb-8 whitespace-pre-wrap"
							id="vervangAanhef"
							key={index}
						>
							{line}
						</p>
					);
				})}
		</div>
	);
};

export default DocumentContent;
