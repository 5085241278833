import React from "react";

const Container = ({ className, ...props }) => {
	return (
		<div className={className} {...props}>
			<div
				className={`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 pb-10`}
			>
				{props.children}
			</div>
		</div>
	);
};

export default Container;
