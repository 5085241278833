import React from "react";

import Card from "../../shared/components/UIElements/Card";
import FormattedDate from "../../shared/components/util/FormattedDate";
import IconWithText from "../../shared/components/UIElements/IconWithText";
import {
	BriefcaseIcon,
	LifebuoyIcon,
	UserIcon,
} from "@heroicons/react/20/solid";
import { IconDental } from "@tabler/icons-react";

const DocumentItem = (props) => {
	console.log(props);
	return (
		<Card
			link={`/document/${props.id}`}
			footer={<FormattedDate date={props.dateCreated} />}
		>
			<div className="flex items-center space-x-3">
				<h3 className="text-gray-900 text-lg font-semibold truncate">
					{props.documentType}
				</h3>
			</div>
			<div className="sm:flex items-center sm:space-x-6 mt-3">
				{props.info.treatment && (
					<IconWithText
						text={props.info.treatment.name}
						icon={<LifebuoyIcon />}
					/>
				)}
				{props.info.patientGender && props.info.patientAge && (
					<IconWithText
						text={
							(props.info.patientGender === "male"
								? "Man"
								: "Vrouw") +
							" van " +
							props.info.patientAge +
							" jaar"
						}
						icon={<UserIcon />}
					/>
				)}
				{props.info.patientOccupation && (
					<IconWithText
						text={props.info.patientOccupation}
						icon={<BriefcaseIcon />}
					/>
				)}
			</div>
		</Card>
	);
};

export default DocumentItem;
