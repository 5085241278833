import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@clerk/clerk-react";
import { toast } from "react-toastify";

export const useSubmitForm = (setFormData) => {
	const navigate = useNavigate();
	const [result, setResult] = useState("");
	const resultRef = useRef();
	const { userId } = useAuth();

	const handleSubmitForm = async (documentType, values) => {
		navigate("/document/generating");
		setResult("");
		setFormData(null);

		toast("Doument aan het genereren...", {
			position: "bottom-center",
			theme: "light",
			autoClose: 1500,
		});

		let newDocument = {
			creatorId: userId,
			content: "",
			documentType: documentType,
			info: values,
			dateCreated: new Date(),
		};

		resultRef.current = newDocument;
		setResult(resultRef.current);
		setFormData({ ...resultRef.current });

		let documentTypeParams = `documentType=${encodeURIComponent(
			JSON.stringify(documentType)
		)}`;

		let infoParams = `info=${encodeURIComponent(JSON.stringify(values))}`;

		let url = `${process.env.REACT_APP_BACKEND_URL}/api/documents/generate/?${documentTypeParams}&creatorId=${userId}&${infoParams}`;

		let source = new EventSource(url, {
			headers: {
				"Content-Type": "application/json",
			},
			method: "GET",
		});

		source.onmessage = function (event) {
			const parsedMessage = JSON.parse(event.data);

			if (parsedMessage.id) {
				resultRef.current.id = parsedMessage.id;
				setResult(resultRef.current);
				setFormData({ ...resultRef.current });
			}
			if (event.data) {
				if (event.data !== '"[DONE]"') {
					let text = JSON.parse(event.data);

					if (text !== "\n" && !text.id) {
						// if (text.includes(",") && !firstCommaReached) {
						// 	setFirstCommaReached(true);
						// 	const indexOfComma = text.indexOf(",");
						// 	text = "BAAA" + text.slice(indexOfComma + 1);
						// }

						resultRef.current.content =
							(resultRef.current.content || "") + text;
						setResult(resultRef.current);
						setFormData({ ...resultRef.current });
					}
				} else {
					source.close();
				}
			}
		};

		source.onerror = function (event) {
			console.log("EventSource failed:", event);
		};
	};

	return handleSubmitForm;
};
