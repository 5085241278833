import React from "react";

import SignUpForm from "../components/SignUpForm";
import MainLogo from "../../shared/components/Navigation/MainLogo";

import Heading from "../../shared/components/UIElements/Text/Heading";
import AuthFooter from "../components/AuthFooter";
import AuthPromo from "../components/AuthPromo";

const SignUpPage = () => {
	return (
		<div className="bg-white h-screen grid sm:grid-cols-2">
			<div className="grid content-between px-12 sm:px-24">
				<MainLogo className="mt-12 mb-12 sm:mb-0" />

				<div>
					<div className="pb-12">
						<Heading className="leading-9">
							Start gratis met DentAI
						</Heading>
						<p className="pt-4 text-gray-700">
							Maak nu je gratis account aan en genereer direct je
							eerste brief
						</p>
					</div>

					<SignUpForm />
					<p className="pt-6 center text-sm text-gray-800">
						Heb je al een account?{"  "}
						<a
							href="/inloggen"
							className="text-indigo-500 font-semibold hover:text-indigo-600 ml-1"
						>
							Log hier in
						</a>
					</p>
				</div>

				<div className="mb-12 sm:flex justify-between">
					<AuthFooter />
				</div>
			</div>
			<div className="bg-indigo-500 my-8 mr-8 rounded-2xl p-32 text-white hidden lg:block">
				<AuthPromo />
			</div>
		</div>
	);
};

// const SignUpPage = () => {
// 	return (
// 		<div className="bg-slate-50 py-20 h-screen">
// 			<Container>
// 				<div className="grid grid-cols-2">
// 					<div className="my-auto">
// 						<Heading>
// 							Meld je nu aan en probeer DentGPT gratis uit
// 						</Heading>
// 						<p className="pt-6 text-gray-800">
// 							Lorem ipsum dolor sit amet, consectetur adipiscing
// 							elit. Sed in risus quis lectus fermentum aliquet.
// 							Sed vitae nisl eget nunc ultricies aliquam. Sed in
// 							risus quis lectus fermentum aliquet. Sed vitae nisl
// 							eget nunc ultricies aliquam.
// 						</p>
// 					</div>
// 					<div className="my-auto pl-10">
// 						<div className="bg-white p-12 pt-16 rounded-3xl shadow-md">
// 							<SignUpForm />
// 							<p className="pt-6 center text-sm text-gray-800">
// 								Heb je al een account?{"  "}
// 								<a
// 									href="/login"
// 									className="text-indigo-500 hover:text-indigo-600 ml-1"
// 								>
// 									Log in
// 								</a>
// 							</p>
// 						</div>
// 					</div>
// 				</div>
// 			</Container>
// 		</div>
// 	);
// };

export default SignUpPage;
