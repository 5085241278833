import React, { useEffect, useState } from "react";

import { HandThumbDownIcon, HandThumbUpIcon } from "@heroicons/react/24/solid";

import FeedbackForm from "./FeedbackForm";

const FeedbackButton = (props) => {
	const documentId = props.documentId;
	const documentType = props.documentType;

	return (
		<div className="flex center text-blueGray-400 space-x-1">
			<button>
				<FeedbackForm
					documentId={documentId}
					documentType={documentType}
					rating="1"
					className="bg-blueGray-200 p-2 rounded-lg hover:text-blueGray-500 hover:scale-105"
				>
					<HandThumbUpIcon className="w-5" />
				</FeedbackForm>
			</button>
			<button>
				<FeedbackForm
					documentId={documentId}
					documentType={documentType}
					rating="0"
					className="bg-blueGray-200 p-2 rounded-lg hover:text-blueGray-500 hover:scale-105"
				>
					<HandThumbDownIcon className="w-5" />
				</FeedbackForm>
			</button>
		</div>
	);
};

export default FeedbackButton;
