import React from "react";

import DocumentItem from "./DocumentItem";
import Button from "../../shared/components/FormElements/Button";

const DocumentList = (props) => {
	const documents = props.documents;

	if (documents.length === 0) {
		return (
			<div className="flex flex-col items-center justify-center w-full h-full py-32">
				<p className="text-gray-500 text-lg">
					Je hebt nog geen brieven gemaakt met DentGPT.
				</p>
				<Button to="/" className="mt-6">
					Maak je eerste brief
				</Button>
			</div>
		);
	}

	return (
		<>
			{documents.map((document) => (
				<DocumentItem
					key={document.id}
					id={document.id}
					info={document.info}
					dateCreated={document.dateCreated}
					documentType={document.documentType.title}
				/>
			))}
		</>
	);
};

export default DocumentList;
