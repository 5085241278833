import React from "react";
import { Link } from "react-router-dom";

const Button = (props) => {
	const { classNames, ...rest } = props;

	const baseClasses =
		classNames +
		" transition ease-in-out duration-300 rounded-lg bg-indigo-600 mt-5 hover:scale-105 px-6 py-3 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600";

	if (props.href) {
		return (
			<a className={baseClasses} href={props.href}>
				{props.children}
			</a>
		);
	}
	if (props.to) {
		return (
			<Link to={props.to} className={baseClasses}>
				{props.children}
			</Link>
		);
	}
	return (
		<button
			className={baseClasses}
			documentType={props.documentType}
			onClick={props.onClick}
			disabled={props.disabled}
			{...rest}
		>
			{props.children}
		</button>
	);
};

export default Button;
