import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { ClerkProvider } from "@clerk/clerk-react";
import { nlNL } from "@clerk/localizations";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import IntercomAppWrapper from "./IntercomAppWrapper";

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<React.StrictMode>
		<ClerkProvider publishableKey={clerkPubKey} localization={nlNL}>
			<IntercomAppWrapper />
			<ToastContainer
				theme="dark"
				pauseOnHover
				autoClose={3500}
				hideProgressBar={true}
			/>
		</ClerkProvider>
	</React.StrictMode>
);
