import React from "react";
import { Link } from "react-router-dom";

const Card = (props) => {
	return (
		<Link to={props.link}>
			<div
				className={
					props.className +
					" bg-white mt-6 overflow-hidden shadow rounded-lg divide-y divide-gray-200 transition duration-200 ease-in-out hover:shadow-md"
				}
			>
				<div className="px-4 py-5 sm:p-6">{props.children}</div>
				{props.footer && (
					<div className="px-4 py-4 sm:px-6 text-gray-500 text-sm truncate">
						{props.footer}
					</div>
				)}
			</div>
		</Link>
	);
};

export default Card;
