import React from "react";

import Heading from "../../shared/components/UIElements/Text/Heading";
import { SparklesIcon } from "@heroicons/react/24/solid";

const AuthPromo = () => {
	return (
		<>
			<SparklesIcon className="h-10 w-auto mb-4 text-indigo-200" />
			<Heading className="text-white leading-10">
				Handige AI tools voor het hele praktijkteam
			</Heading>
			<p className="text-indigo-100 mt-5 leading-7">
				Corrigeer in een handomdraai spel- en taalfouten, leg op een
				begrijpelijke manier het belang van een behandeling uit voor de
				patient en gebruik de <strong>magie van DentAI </strong>
				voor talloze andere doeleinden in de praktijk!
			</p>
		</>
	);
};

export default AuthPromo;
