import React from "react";

import { ClipboardDocumentIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";

const PasteButton = (props) => {
	const readClipboard = async () => {
		try {
			const text = await navigator.clipboard.readText();
			props.handlePaste(text);

			toast.success("Geplakt vanaf je klembord", {
				position: "bottom-center",
				theme: "light",
				icon: (
					<CheckCircleIcon
						className=" text-green-500"
						aria-hidden="true"
					/>
				),
			});
		} catch (err) {
			console.error("Failed to read clipboard contents: ", err);
		}
	};

	return (
		<p
			onClick={readClipboard}
			classNames="w-full center cursor-pointer hover:scale-105"
		>
			<div className="cursor-pointer flex text-sm text-indigo-600 hover:text-indigo-700 font-medium pt-2">
				<ClipboardDocumentIcon className="w-5 mr-1" />
				Plakken van klembord
			</div>
		</p>
	);
};

export default PasteButton;
