import React from "react";
import { useField } from "formik";

const SelectAge = ({ label, ...props }) => {
	const [field, meta] = useField(props);

	return (
		<>
			<div>
				<label
					htmlFor={props.id || props.name}
					className="block text-sm font-medium leading-6 text-gray-900"
				>
					{label}
				</label>
				<div className="relative mt-2 rounded-md shadow-sm">
					<input
						{...field}
						{...props}
						type="number"
						className="block w-full rounded-md border-0 py-1.5 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
					/>
					<div className="absolute inset-y-0 right-0 flex items-center">
						<div className="my-auto rounded-md border-0 bg-transparent py-0 pl-2 pr-5 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm">
							jaar oud
						</div>
					</div>
					{meta.touched && meta.error ? (
						<div className="error">{meta.error}</div>
					) : null}
				</div>
			</div>
		</>
	);
};

export default SelectAge;
