import React from "react";

import { useAuth, useUser } from "@clerk/clerk-react";
import { SliderButton } from "@typeform/embed-react";
import { toast } from "react-toastify";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

const FeedbackForm = (props) => {
	const { getToken } = useAuth();

	const { user } = useUser();

	const hiddenFields = {
		user_id: user.id,
		name: `${user.firstName} ${user.lastName}`,
		document_id: props.documentId,
		document_type: props.documentType,
		rating: props.rating,
	};

	const handleClick = async () => {
		toast.success("Feedback gegeven", {
			position: "bottom-center",
			theme: "light",
			icon: (
				<CheckCircleIcon
					className=" text-green-500"
					aria-hidden="true"
				/>
			),
			autoClose: 10000,
		});

		try {
			const response = await fetch(
				`http://localhost:5001/api/documents/feedback/${props.documentId}`,
				{
					method: "PATCH",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${await getToken()}`,
					},
					body: JSON.stringify({
						feedbackScore: props.rating,
					}),
				}
			);

			if (response.ok) {
				const data = await response.json();
				console.log(data);
			} else {
				console.error("Failed to update feedback");
			}
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	return (
		<button onClick={handleClick}>
			<SliderButton
				id="hIb3en7G"
				buttonText=""
				hidden={hiddenFields}
				{...props}
			>
				{props.children}
			</SliderButton>
		</button>
	);
};

export default FeedbackForm;
