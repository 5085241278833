import React from "react";

import { useUser } from "@clerk/clerk-react";
import { PopupButton } from "@typeform/embed-react";
import { toast } from "react-toastify";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

import Heading from "../../shared/components/UIElements/Text/Heading";
import questionMarkEmoji from "../../img/QuestionMarkEmoji.png";

const RequestNewType = () => {
	const { user } = useUser();

	const hiddenFields = {
		user_id: user.id,
		name: `${user.firstName} ${user.lastName}`,
	};

	function handleSubmit() {
		const esc = new KeyboardEvent("keydown", {
			key: "Escape",
			code: "Escape",
			charCode: 27,
			keyCode: 27,
			view: window,
		});
		document.dispatchEvent(esc);

		toast.success("Aanvraag ingediend", {
			position: "bottom-center",
			theme: "light",
			icon: (
				<CheckCircleIcon
					className=" text-green-500"
					aria-hidden="true"
				/>
			),
		});
	}

	return (
		<PopupButton
			id="NVYLkzKD"
			buttonText=""
			hidden={hiddenFields}
			onSubmit={handleSubmit}
		>
			<div className="border-slate-200 border-3 px-6 py-8 sm:px-12 sm:py-14 bg-white rounded-3xl hover:scale-105 transition-all ease-in-out text-left h-full">
				<img
					src={questionMarkEmoji}
					alt="Questionmark emoji"
					className="bg-slate-50 p-6 w-24 h-24 rounded-full aspect-square"
				/>
				<Heading size="3" className="mt-6">
					Vraag nieuw document aan
				</Heading>
				<p className="mt-2 text-sm text-gray-500">
					Mis je iets? Laat het ons weten.
				</p>
			</div>
		</PopupButton>
	);
};

export default RequestNewType;
