import React from "react";

const FormattedDate = (props) => {
	const date = new Date(props.date);
	const now = new Date();

	const getHour = (date) =>
		new Intl.DateTimeFormat("nl", {
			hour: "numeric",
			minute: "numeric",
			hour12: false,
			timeZone: "Europe/Amsterdam",
		}).format(date);

	const getDayName = (date) =>
		new Intl.DateTimeFormat("nl", { weekday: "long" }).format(date);

	if (isToday(date, now)) {
		return <span>{`Vandaag om ${getHour(date)} uur`}</span>;
	} else if (isYesterday(date, now)) {
		return <span>{`Gisteren om ${getHour(date)} uur`}</span>;
	} else if (isLastWeek(date, now)) {
		const dayName = getDayName(date);
		return (
			<span>{`${
				dayName.charAt(0).toUpperCase() + dayName.slice(1)
			} om ${getHour(date)} uur`}</span>
		);
	} else {
		const formattedDate = new Intl.DateTimeFormat("nl", {
			day: "numeric",
			month: "long",
			year: "numeric",
		}).format(date);
		return <span>{`${formattedDate} om ${getHour(date)} uur`}</span>;
	}
};

const isToday = (date, now) => {
	return date.toDateString() === now.toDateString();
};

const isYesterday = (date, now) => {
	const yesterday = new Date(now.setDate(now.getDate() - 1));
	return date.toDateString() === yesterday.toDateString();
};

const isLastWeek = (date, now) => {
	const oneWeekAgo = new Date(now.setDate(now.getDate() - 7));
	return date >= oneWeekAgo;
};

export default FormattedDate;
