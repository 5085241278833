import React from "react";

import Avatar from "boring-avatars";
import { useUser } from "@clerk/clerk-react";

const ProfileImage = (props) => {
	const { user } = useUser();

	return (
		<Avatar
			size={40}
			variant="beam"
			colors={["#0A0310", "#49007E", "#FF005B", "#FF7D10", "#FFB238"]}
			name={props.name || user.firstName + " " + user.lastName}
		/>
	);
};

export default ProfileImage;
