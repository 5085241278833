import { useState } from "react";

import { IconMan, IconWoman } from "@tabler/icons-react";

const SelectGender = ({ setFieldValue, ...props }) => {
	const [gender, setGender] = useState("male");

	const handleGenderChange = (event, newGender) => {
		event.preventDefault();
		setGender(newGender);
		setFieldValue("patientGender", newGender);
	};

	return (
		<div className="mt-6">
			<label
				htmlFor={props.id || props.name}
				className="block text-sm font-medium leading-6 text-gray-900 mb-2"
			>
				{props.label}
			</label>
			<div className="flex items-center">
				<div
					className="flex space-x-1 rounded-lg bg-slate-100 p-0.5"
					role="tablist"
					aria-orientation="horizontal"
				>
					<button
						className={`flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm font-semibold lg:pr-3 ${
							gender === "female"
								? "bg-white text-slate-900 shadow"
								: "text-slate-600"
						}`}
						onClick={(event) => handleGenderChange(event, "female")}
						aria-selected={gender === "female"}
						tabindex="0"
					>
						<IconWoman
							color={gender === "female" ? "#6366F1" : "black"}
						/>
						<span className="sr-only lg:not-sr-only lg:ml-2">
							Vrouw
						</span>
					</button>
					<button
						className={`flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm font-semibold lg:pr-3 ${
							gender === "male"
								? "bg-white text-slate-900 shadow"
								: "text-slate-600"
						}`}
						onClick={(event) => handleGenderChange(event, "male")}
						aria-selected={gender === "male"}
						tabindex="0"
					>
						<IconMan
							color={gender === "male" ? "#6366F1" : "black"}
						/>
						<span className="sr-only lg:not-sr-only lg:ml-2">
							Man
						</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default SelectGender;
