import React from "react";

import SignInForm from "../components/SignInForm";
import MainLogo from "../../shared/components/Navigation/MainLogo";

import Heading from "../../shared/components/UIElements/Text/Heading";
import AuthFooter from "../components/AuthFooter";
import AuthPromo from "../components/AuthPromo";

const Login = () => {
	return (
		<div className="bg-white h-screen grid sm:grid-cols-2">
			<div className="grid content-between px-12 sm:px-24">
				<MainLogo className="mt-12" />

				<div>
					<div className="pb-12">
						<Heading>Welkom terug!</Heading>
						<p className="pt-4 text-gray-700">
							Log in om door te gaan naar DentAI
						</p>
					</div>

					<SignInForm />
					<p className="pt-6 sm:center text-sm text-gray-800">
						Heb je nog geen account?{"  "}
						<a
							href="/aanmelden"
							className="text-indigo-500 font-semibold hover:text-indigo-600 ml-1"
						>
							Maak je gratis account aan
						</a>
					</p>
				</div>

				<div className="mb-12 sm:flex justify-between">
					<AuthFooter />
				</div>
			</div>
			<div className="bg-indigo-500 my-8 mr-8 rounded-2xl p-32 text-white hidden sm:block">
				<AuthPromo />
			</div>
		</div>
	);
};

// const Login = () => {
// 	return (
// 		<>
// 			<div className="bg-slate-50 py-20 h-screen">
// 				<Container>
// 					<div className="grid grid-cols-2">
// 						<div className="my-auto">
// 							<Heading>
// 								Welkom terug! Log in om verder te gaan.
// 							</Heading>
// 							<p className="pt-6 text-gray-800">
// 								Met DentGPT maak je in een handomdraai
// 								documenten voor patiënten.
// 							</p>
// 						</div>
// 						<div className="my-auto pl-10">
// 							<div className="bg-white p-12 pt-16 rounded-3xl shadow-md">
// 								<SignInForm />
// 								<p className="pt-6 center text-sm text-gray-800">
// 									Nog geen account?{"  "}
// 									<a
// 										href="/signup"
// 										className="text-indigo-500 hover:text-indigo-600 ml-1"
// 									>
// 										Meld je gratis aan
// 									</a>
// 								</p>
// 							</div>
// 						</div>
// 					</div>
// 				</Container>
// 			</div>
// 		</>
// 	);
// };

export default Login;
