import React from "react";

import toothEmoji from "../../img/ToothEmoji.png";
import Heading from "../../shared/components/UIElements/Text/Heading";
import Variable from "../../shared/components/UIElements/Text/Variable";
import { Link } from "react-router-dom";

const TypeItem = (props) => {
	return (
		<Link to={"/new/" + props.slug}>
			<div className="border-slate-200 border-3 px-6 py-8 sm:px-12 sm:py-14 bg-white rounded-3xl hover:scale-105 transition-all ease-in-out h-full">
				<img
					src={toothEmoji}
					alt="Tooth emoji"
					className="bg-slate-50 p-6 w-24 h-24 rounded-full aspect-square"
				/>

				<Heading size="3" className="mt-6">
					{props.title}{" "}
					{!props.live && <Variable text="🔒 Staging" />}
				</Heading>
				<p className="mt-2 text-sm text-gray-500">
					{props.description}
				</p>
			</div>
		</Link>
	);
};

export default TypeItem;
