import React from "react";

const FormDataContext = React.createContext();

// Create a Context Provider Component
export function FormDataProvider({ children }) {
	const [formData, setFormData] = React.useState(null);

	return (
		<FormDataContext.Provider value={{ formData, setFormData }}>
			{children}
		</FormDataContext.Provider>
	);
}

export function useFormData() {
	const context = React.useContext(FormDataContext);
	if (context === undefined) {
		throw new Error("useFormData must be used within a FormDataProvider");
	}
	return context;
}
