import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { SparklesIcon } from "@heroicons/react/24/solid";

import useFetch from "../../shared/hooks/useFetch";

import Container from "../../shared/components/UIElements/Container";
import Heading from "../../shared/components/UIElements/Text/Heading";
import Header from "../../shared/components/UIElements/Header";
import DynamicForm from "../components/DynamicForm";

import background from "../../img/Background.png";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

const GenerateDocument = () => {
	const [documentType, setDocumentType] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const documentTypeSlug = useParams().documentType;
	const backgroundImageUrl = `${process.env.REACT_APP_FRONTEND_URL}${background}`;

	const fetchDocumentType = useFetch();

	const fetchDocumentTypes = async () => {
		const uri = `/api/documents/documentTypes/${documentTypeSlug}`;

		setIsLoading(true);

		fetchDocumentType(uri)
			.then((data) => {
				setDocumentType(data);
				console.log(data);

				setIsLoading(false);
			})
			.catch((error) => console.error(error));
	};

	useEffect(() => {
		fetchDocumentTypes();
	}, [documentTypeSlug]);

	if (isLoading) {
		return <LoadingSpinner />;
	}

	return (
		<>
			{documentType && (
				<Header
					icon={<SparklesIcon />}
					title={documentType.title}
					description="Nieuw document aanmaken"
				/>
			)}
			<Container
				className="bg-slate-50 pb-24 sm:pt-4 sm:pb-4"
				style={{
					backgroundImage: `url(${backgroundImageUrl})`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "contain",
					backgroundPositionX: "right",
					backgroundPositionY: "bottom",
				}}
			>
				<div className="sm:w-1/3 pt-6 pb-8 px-8 sm:px-12 sm:pb-12 sm:pt-10 bg-white shadow-md rounded-2xl w-full">
					<Heading size="3">Vul een paar details in</Heading>
					{documentType && (
						<div className="pt-4">
							<DynamicForm
								typeSlug={documentType.slug}
								typeId={documentType.id}
								typeTitle={documentType.title}
								vervangAanhef={
									documentType.vervangAanhef || false
								}
								schema={documentType.fields}
							/>
						</div>
					)}
				</div>
			</Container>
			<div style={{ backgroundColor: "#CDD7DD" }} className="py-6"></div>
		</>
	);
};

export default GenerateDocument;
