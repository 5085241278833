import React from "react";
import { useField } from "formik";

const Input = ({ label, ...props }) => {
	const [field, meta] = useField(props);

	return (
		<div
			className={`${props.className} mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6`}
		>
			<div className="col-span-full">
				<label
					htmlFor={props.id || props.name}
					className="block text-sm font-medium leading-6
					text-gray-900"
				>
					{label}
				</label>
				<div className="mt-2">
					<input
						{...field}
						{...props}
						className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 px-3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
					/>
					{meta.touched && meta.error ? (
						<div className="error">{meta.error}</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default Input;
