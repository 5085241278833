import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import ProfileImage from "../../../users/components/ProfileImage";
import { SignOutButton } from "@clerk/clerk-react";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/20/solid";

const ProfileDropdown = (props) => {
	function classNames(...classes) {
		return classes.filter(Boolean).join(" ");
	}

	return (
		<Menu as="div" className="relative ml-3 my-auto">
			<div>
				<Menu.Button className="ml-3 m-auto relative bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
					<span className="sr-only">Open user menu</span>
					<ProfileImage />
				</Menu.Button>
			</div>
			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<Menu.Item>
						{({ active }) => (
							<a
								href="/profile"
								className={classNames(
									active ? "bg-gray-100" : "",
									"block px-4 py-2 text-sm text-gray-700"
								)}
							>
								Inlogvoorkeuren
							</a>
						)}
					</Menu.Item>
					<Menu.Item>
						{({ active }) => (
							<SignOutButton>
								<a
									className={classNames(
										active ? "bg-gray-100" : "",
										"block px-4 py-2 text-sm text-red-700 cursor-pointer flex items-center"
									)}
								>
									Log uit
									<ArrowRightOnRectangleIcon className="w-4 h-4 ml-2" />
								</a>
							</SignOutButton>
						)}
					</Menu.Item>
				</Menu.Items>
			</Transition>
		</Menu>
	);
};

export default ProfileDropdown;
