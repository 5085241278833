import React from "react";
import { Formik, Form } from "formik";

import { useFormData } from "../../shared/context/document-context";
import { useSubmitForm } from "../hooks/useSubmitForm";

import Select from "../../shared/components/FormElements/Select";
import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import SelectGender from "../../shared/components/FormElements/SelectGender";
import SelectTreatment from "../../shared/components/FormElements/SelectTreatment";
import SelectAge from "../../shared/components/FormElements/SelectAge";
import OtherField from "../../shared/components/FormElements/OtherField";
import Textarea from "../../shared/components/FormElements/Textarea";
import SwitchToggle from "../../shared/components/FormElements/Switch";

const DynamicForm = (props) => {
	const schema = props.schema;
	const { typeSlug, typeId, typeTitle, vervangAanhef } = props;
	console.log("Vervangaanhef: ", vervangAanhef);

	const documentType = {
		slug: typeSlug,
		id: typeId,
		title: typeTitle,
		vervangAanhef: vervangAanhef,
	};

	const { setFormData } = useFormData();
	const handleSubmitPromptBtnClicked = useSubmitForm(setFormData);

	const initialValues = {};
	schema.forEach((field) => {
		if (field.type === "selectGender") {
			initialValues[field.name] = "male";
		} else if (field.type === "switch") {
			initialValues[field.name] = false;
		} else {
			initialValues[field.name] = field.defaultValue || "";
		}
	});

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values) => {
				if (values.treatment) {
					values.treatment = JSON.parse(values.treatment);
				}
				handleSubmitPromptBtnClicked(documentType, values);
			}}
		>
			{(props) => (
				<Form>
					{schema.map((field) => {
						if (field.type === "select") {
							return (
								<Select
									name={field.name}
									label={field.label}
									required={field.required}
								>
									<option value="" selected disabled>
										{field.placeholder || "Selecteren..."}
									</option>
									{field.options.map((option) => (
										<option value={option.value || option}>
											{option.label || option}
										</option>
									))}
								</Select>
							);
						} else if (field.type === "selectGender") {
							return (
								<SelectGender
									name={field.name || "patientGender"}
									label={field.label || "Geslacht"}
									required={field.required}
									defaultValue={field.defaultValue || "male"}
									{...props}
								/>
							);
						} else if (field.type === "selectTreatment") {
							return (
								<SelectTreatment
									name={field.name}
									label={field.label}
									required={field.required}
									{...props}
								/>
							);
						} else if (field.type === "selectAge") {
							return (
								<SelectAge
									name={field.name}
									label={field.label}
									required={field.required}
									{...props}
								/>
							);
						} else if (field.type === "textarea") {
							return (
								<Textarea
									label={field.label}
									name={field.name}
									placeholder={field.placeholder || ""}
									required={field.required}
									pasteButton={field.pasteButton || false}
									rows={field.rows || 3}
									{...props}
								/>
							);
						} else if (field.type === "switch") {
							return (
								<SwitchToggle
									label={field.label}
									name={field.name}
									required={field.required}
									description={field.description || false}
									{...props}
								/>
							);
						} else {
							return (
								<Input
									label={field.label}
									name={field.name}
									type={field.type}
									min={field.min}
									max={field.max}
									required={field.required}
								/>
							);
						}
					})}
					<OtherField />
					<Button type="submit" classNames="w-full mt-4">
						Genereer document
					</Button>
				</Form>
			)}
		</Formik>
	);
};

export default DynamicForm;
