import React from "react";

import logo from "../../../img/LogoDentAI.png";

const MainLogo = (props) => {
	return (
		<>
			<img
				className={"block lg:hidden h-8 w-auto " + props.className}
				src={logo}
				alt="DentGPT"
			/>
			<img
				className={"hidden lg:block h-8 w-auto " + props.className}
				src={logo}
				alt="DentGPT"
			/>
		</>
	);
};

export default MainLogo;
