import React from "react";
import { NavLink } from "react-router-dom";

const NavItem = (props) => {
	const { name, link } = props;
	return (
		<NavLink
			className={({ isActive }) => {
				const baseClasses =
					"hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium";
				const extraClasses = isActive
					? "border-indigo-500 text-gray-90"
					: "border-transparent text-gray-500 ";
				return baseClasses + " " + extraClasses;
			}}
			to={link}
		>
			{name}
		</NavLink>
	);
};

export default NavItem;
