import React from "react";

import { SignUp } from "@clerk/clerk-react";

const SignUpForm = () => {
	return (
		<div className="mt-signup">
			<SignUp
				appearance={{
					elements: {
						header: "hidden",
						card: "w-full p-0 m-0 bg-transparent shadow-none",
						rootBox: "w-full",
						formFieldInput:
							"rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 px-3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
						formButtonPrimary:
							"bg-indigo-600 hover:bg-indigo-700 hover:scale-105 transition ease-in-out duration-300 rounded-lg normal-case text-base shadow-sm font-semibold tracking-normal py-3 text-white",
						footerActionLink:
							"text-indigo-600 hover:text-indigo-700",
						footer: "hidden",
					},
				}}
				localization={{
					formFieldErrorText_emailAddress:
						"Dat e-mailadres is al in gebruik. Probeer een ander.",
				}}
			/>
		</div>
	);
};

export default SignUpForm;
